<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div class="tunes-wrapper" v-else>
      <ul>
        <li v-for="(data,index) in dataDetails.sections">
          <div class="tunes-box">
            <div class="tune-img">
              <img :src="data.image_link" alt="">
            </div>
            <div class="tune-details">
              <h3>{{data.text}}<span>{{ data.singer_text }}</span></h3>
            </div>
            <div class="tune-function">
              <a href="javascript:void(0)" @click="playCallerTune(data.page_id,data.event_id,index)" v-if="index == audioIndex" :class="isPlaying?'play-voice playing pause-voice mute':'play-voice'" data-text="Play" data-page_id="122" data-event_id="349"
                 data-audio_name="">
                <audio class="audio-tune" ref="player" type="audio/mpeg" style="display: none">
                </audio>
              </a>
              <a href="javascript:void(0)" @click="playCallerTune(data.page_id,data.event_id,index)" v-else class="play-voice" data-text="Play" data-page_id="122" data-event_id="349"
                 data-audio_name="">
                <audio class="audio-tune" type="audio/mpeg" style="display: none">
                </audio>
              </a>
            </div>
            <a :href="'tel:'+data.tel" class="btn dial-subscribe" data-option_id="197" data-service_id="10"
               data-language="bn" data-tel="">
              {{ data.active_text }}
            </a>
          </div>
        </li>
      </ul>
      <div class="note">** {{ dataDetails.first_month_text }} RM <strong>3</strong> {{dataDetails.second_month_text}} RM
        <strong>2</strong>{{dataDetails.charge_applicable_text}}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'

export default {
  name: 'multipleServiceDetails',
  data () {
    return {
      apiUrl: '/content/caller-tune-',
      dataDetails: {},
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      is_loading: true,
      subscriptionStatus: '',
      sessionId: sessionStorage.getItem('sessionId'),
      service: sessionStorage.getItem('service'),
      operatorId: localStorage.getItem('operatorId'),
      isPlaying: false,
      audioIndex: null,
      audioPlayer: null
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      this.apiUrl += this.service
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, null, { params: { languageId: this.languageId } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    submit () {
      sessionStorage.setItem('sub_service', this.service)
      this.$router.push(this.dataDetails.service.visit_url)
    },
    playCallerTune (pageID, eventID, index) {
      this.audioIndex = index
      this.isPlaying = !this.isPlaying
      console.log(pageID, eventID)
      this.$store.commit('toggleAudio')
      if (this.isPlaying)
      {
        console.log('if')
        updateEvent(pageID, eventID)
      }
      // this.audioPlayer = this.$refs.player
      // // var audio = document.getElementById("audio-player");
      // if (this.audioPlayer.paused) {
      //   this.audioPlayer.play()
      // } else {
      //   this.audioPlayer.pause()
      // }
    }
  }
}
</script>

<style scoped>

</style>
